<template>
  <div>
    <v-card-text>
      <v-alert v-if="!documentReady" text type="info"
        >No hay data para mostrar</v-alert
      >
      <v-card v-else outlined class="white pa-2">
        <iframe
          id="frameID"
          style="border: 2px solid rgb(237, 237, 237)"
          width="100%"
          :height="window.height"
          :src="urlReportViewer"
        ></iframe>
      </v-card>
    </v-card-text>

    <v-fab-transition>
      <v-btn
        v-if="$vuetify.breakpoint.mobile"
        color="transparent"
        absolute
        top
        right
        fixed
        style="top: 120px; right: 0px"
        @click.native="drawerRight = !drawerRight"
      >
        <v-icon>filter_alt</v-icon>
      </v-btn>
    </v-fab-transition>

    <v-navigation-drawer v-model="drawerRight" app clipped fixed right>
      <v-card-text>
        <div class="subtitle-1 py-5">Filtros</div>
        <v-divider></v-divider>
        <br />
        <v-form ref="form">
          <v-select
            :items="listaReporte"
            v-model="selectedReport"
            :rules="reporteValidation"
            item-text="Nombre"
            item-value="ReporteCode"
            placeholder="Seleccione reporte"
            :disabled="processing"
            hint="Filtro por reporte"
            append-icon="keyboard_arrow_down"
            persistent-hint
          ></v-select>
        </v-form>
        <br />
        <div class="mb-5 text-right">
          <v-btn
            block
            class="ma-1"
            depressed
            color="primary"
            @click="generarReporte"
          >
            <v-icon left>search</v-icon>Buscar
            <span slot="loader">Buscando...</span>
          </v-btn>
        </div>
      </v-card-text>
    </v-navigation-drawer>
  </div>
</template>

<script>
import apiReporte from "@/api/reportes";
import { mapActions } from "vuex";
import { global } from "@/api/global";
import moment from "moment";

let _baseurl = global.baseWebUrl + "mvc/report/";

export default {
  data: () => {
    return {
      drawerRight: true,
      selectedReport: null,
      documentReady: true,
      listaReporte: [],
      processing: false,
      urlReportViewer: global.baseWebUrl + "loader.html",
      corteValidation: [(v) => !!v || "Debe seleccionar un corte"],
      reporteValidation: [(v) => !!v || "Debe seleccionar un reporte"],
      selectedSistema: "Indac",
      window: {
        width: 0,
        height: 0,
      },
      breadcrumb: [
        {
          text: "Reportes",
          disabled: true,
        },
      ],
    };
  },
  computed: {
    listaCortes() {
      return this.$store.getters.cortes;
    },
    currentUser() {
      return this.$store.getters.user;
    },
  },
  methods: {
  
    ...mapActions(["setError", "setErrorMessage", "setMessage"]),
    getReportes() {
      if (this.$store.getters.user.canalid) {
        apiReporte
          .GetReportListByCanalID(this.$store.getters.user.canalid, this.selectedSistema)
          .then((response) => {
            this.listaReporte = response.data;
            this.documentReady = false;
          })
          .catch((error) => {
            this.setError(error);
            this.documentReady = false;
          });
      } else {
        this.setMessage("Favor seleccionar un corte primero");
      }
    },
    generarReporte() {
      if (this.$refs.form.validate()) {
        this.documentReady = true;
        this.processing = true;
        this.urlReportViewer = global.baseWebUrl + "loader.html";

        var self = this;
        setTimeout(function () {
          var fechaInicio = moment(
            self.$store.getters.user.corte.StartDate
          ).format("YYYY-MM-DD");

          var fechaFin = moment(self.$store.getters.user.corte.EndDate).format(
            "YYYY-MM-DD"
          );

          self.processing = false;
          self.urlReportViewer =
            _baseurl +
            "ReporteEmpleadoViewer?" +
            "ReporteCode=" +
            self.selectedReport +
            "&Empleado=" +
            JSON.stringify(
              self.$store.getters.user.seleccion.empleados.map(
                (c) => c.EmpleadosId
              )
            ) +
            "&CanalSucursal=" +
            JSON.stringify(
              self.$store.getters.user.seleccion.gestionados.map(
                (c) => c.CanalSucursalId
              )
            ) +
            "&CorteId=" +
            JSON.stringify(self.$store.getters.user.corte.CorteId) +
            "&CanalId=" +
            self.$store.getters.user.corte.CanalId +
            "&FechaInicio=" +
            fechaInicio +
            "&FechaFin=" +
            fechaFin;
        }, 2000);
      }
    },
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight - 210;
    },
  },
  mounted() {
    // this.$store.dispatch("setBreadcrumbs", this.breadcrumb);
    if (this.currentUser.seleccion == undefined) {
      this.setMessage(
        "Primero debe seleccionar el corte para navegar en el sistema"
      );
    } else {
      this.getReportes();
    }
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>