import { global } from './global'
import { store } from '@/store'

let _baseurl = global.baseApiUrl + 'reporte/'

export default {
  GetSistemas() {
    return global.axios.get(_baseurl + 'get-sistemas')
  },

  GetReportListByCanalID(canalId,sistema) {
    return global.axios.get(_baseurl + 'get-report-list-by-canal-id?canalId=' + canalId + '&sistema='+sistema)
  },
  GetGeneralReportListByCanalID(canalId) {
    return global.axios.get(_baseurl + 'get-general-report-list-by-canal-id?canalId=' + canalId)
  },
  GetRiesgoReports() {
    return global.axios.get(_baseurl + 'get-riesgo-report')
  },
  GetRiesgoReportsEncabezado(reportId) {
    return global.axios.get(_baseurl + 'get-riesgo-report-encabezado?reportID=' + reportId + '&codAsociado=' + store.getters.user.asociado.codigo)
  },
  // GetRiesgoReportsDetalle (encabezadoId, producto) {
  //   return global.axios.get(_baseurl + 'GetRiesgoReportsDetalle?encabezadoID=' + encabezadoId + '&producto=' + producto)
  // },
  ImportarReporte(parametros) {
    return global.axios.post(_baseurl + 'importar-reporte', parametros, { responseType: 'blob', headers: { 'Accept': 'application/vnd.ms-excel', 'Content-Type': 'application/json' } })
  },
  getReporteMasivas(data) {
    return global.axios.post(_baseurl + 'get-reporte-masiva', data, { responseType: 'blob', headers: { 'Accept': 'application/vnd.ms-excel', 'Content-Type': 'application/json' } })
  },
  getReporteMasivasArchivo(data) {
    return global.axios.post(_baseurl + 'get-reporte-masiva-archivo', data, { responseType: 'blob', headers: { 'Accept': 'application/vnd.ms-excel', 'Content-Type': 'application/json' } })
  },
  GenerarFacturaNotaCredito(data) {
    return global.axios.post(_baseurl + 'generar-factura-nota-credito', data, { responseType: 'blob', headers: { 'Accept': 'application/pdf', 'Content-Type': 'application/json' } })
  },
  GenerarYSubirFacturaNotaCredito(data) {
    return global.axios.post(_baseurl + 'generar-subir-factura-nota-credito', data, { responseType: 'blob', headers: { 'Accept': 'application/pdf', 'Content-Type': 'application/json' } })
  },
}
